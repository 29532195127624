<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :dense="true" title="Resumen del Documento" :breadcrumbs="breadcrumbs"
      :breadcrumbsItemDinamic="{ loading: $store.state.purchases.retrieveLoader, text: `${(activeDocument || {}).name} Nº ${instance.number}` }"
      :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <!-- <v-btn @click="downloadFile('purchases', `${instance.document_type}_N${instance.number}`, 'purchases', 'xml', instance.id)" :disabled="$store.state.purchases.retrieveLoader" outlined><v-icon left>mdi-download</v-icon>Descargar XML</v-btn> -->
        <template v-if="['PEN', null].includes(instance?.status) && $helpers.hasPermission(['add_swapresponse'])">
          <v-btn @click="dialogSwap = true, dialogSwapType = 'accept'" :disabled="btnDisable >= 7" outlined :ripple="false">
            <v-icon left>mdi-file-document-check</v-icon>
            Aceptar
          </v-btn>
          <v-btn class="ml-2" @click="dialogSwap = true, dialogSwapType = 'reclaim'" :disabled="btnDisable >= 7" color="blue-500" :ripple="false">
            <v-icon left>mdi-file-document-remove</v-icon>
            Reclamar
          </v-btn>
        </template>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="$store.state.purchases.retrieveLoader" />
      <div class="d-flex" v-else>
        <!-- detail card -->
        <div class="mr-6" style="min-width: 425px;">
          <div style="position:fixed!important;">
            <v-card class="blue-500 pb-4" width="425">
              <span class="px-4 pt-4 subtitle-1 white--text font-weight-bold d-inline-block text-truncate"
                style="max-width: 98%;">
                {{ instance.document_type | nameDocumentsType }}
              </span>
              <span class="px-4 mt-n2 subtitle-2 white--text d-block">{{ `Nº ${instance.number}` }}</span>
              <v-tooltip color="white-0" right max-width="185px" transition="scale-transition" open-delay="1000">
                <template v-slot:activator="{ on }">
                  <span class="px-4 mt-4 d-block white--text body-1 d-inline-block text-truncate"
                    style="max-width: 98%;" v-on="instance?.supplier?.name?.length > 40 ? on : false">
                    {{ ((instance || {}).supplier || {}).name }}
                  </span>
                </template>
                <span class="d-block px-3 py-2">{{ ((instance || {}).supplier || {}).name }}</span>
              </v-tooltip>
              <span class="px-4 subtitle-2 font-weight-bold white--text">{{ ((instance || {}).supplier ||
                {}).tax_id }}</span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha del doc.</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold" v-if="instance.date">{{ instance.date | date }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </span>
                </v-col>
                <v-col cols="6" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha de recepción en SII</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold" v-if="instance.date_recep_sii">{{ instance.date_recep_sii |
                      dateTimeSecond }}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </span>
                </v-col>
              </v-row>
              <v-col cols="6" class="px-4 pb-4 d-none">
                <span class="d-block body-1 white--text">Sincronizado el</span>
                <span class="subtitle-2 white--text">
                  <span class="font-weight-bold" v-if="instance.created">{{ instance.created | dateTimeSecond }}</span>
                  <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                </span>
              </v-col>
            </v-card>
            <v-card class="pa-0 mt-4" width="425" transition="slide-y-transition" min-height="calc(100% - 365px)" flat
              style="position:fixed!important;">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="px-4 subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="18" left color="grey-500">mdi-currency-usd</v-icon>Totales</v-toolbar-title>
              </v-toolbar>
              <v-divider />
              <v-card-text class="overflow-y-auto pa-0"
                :style="$vuetify.breakpoint.height <= 700 ? 'max-height: calc(100vh - 460px)' : 'max-height: calc(100vh - 300px)'">
                <!-- totals -->
                <v-col class="px-4">
                  <v-list class="transparent pa-0 mt-2" width="100%">
                    <v-list-item>
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left
                          color="grey-500">mdi-currency-usd</v-icon>Subtotal</v-list-item-title>
                      <v-list-item-subtitle class="text-right font-weight-bold">{{ instance.subtotal |
                        currency(instance.document_type) }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left
                          color="grey-500">mdi-sale</v-icon>Descuento</v-list-item-title>
                      <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">
                        <template v-if="(instance || {}).amount_discount">
                          <template v-if="instance.discount">
                            {{ instance.discount | number }}
                            <template v-if="instance.discount_scheme === '%'">{{ instance.discount_scheme }}</template>
                            <template v-if="instance.discount_scheme === '$'">{{ instance.document_type |
                              firstDocumentsTypeCurrency }}</template>
                          </template>
                        </template>
                        <template v-else>-</template>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <template v-if="(activeDocument || {}).has_taxes_detail">
                      <v-list-item class="mt-2">
                        <v-list-item-title class="d-flex align-center"><v-icon size="16" left
                            color="grey-500">mdi-hand-coin</v-icon>Exento</v-list-item-title>
                        <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{ instance.exempt |
                          currency(instance.document_type) }}</v-list-item-subtitle>
                      </v-list-item>
                      <template v-if="(activeDocument || {}).has_taxes">
                        <v-list-item>
                          <v-list-item-title class="d-flex align-center"><v-icon size="16" left
                              color="grey-500">mdi-hand-coin</v-icon>Neto</v-list-item-title>
                          <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{ instance.amount |
                            currency(instance.document_type) }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="mt-2">
                          <v-list-item-title class="d-flex align-center"><v-icon size="16" left
                              color="grey-500">mdi-cash-plus</v-icon>Impuestos
                            ({{ activeDocument.tax }}%)</v-list-item-title>
                          <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{ instance.tax |
                            currency(instance.document_type) }}</v-list-item-subtitle>
                        </v-list-item>
                      </template>
                    </template>
                    <v-list-item class="mt-2">
                      <v-list-item-title class="grey-500--text font-weight-bold">Total documento</v-list-item-title>
                      <v-list-item-subtitle class="text-right subtitle-2 grey-500--text font-weight-bold">{{
                        instance.total
                        | currency(((instance || {}).document_type || ''))}}
                        <template v-if="Object.keys(instance.exchange || {}).length">{{ instance.exchange.currency_from
                          || instance.exchange.currency_to || instance.exchange.currency }}</template>
                        <template v-else>{{ ((instance || {}).document_type || '') |
                          firstDocumentsTypeCurrency }}</template>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-col>
                <!-- end totals -->
              </v-card-text>
            </v-card>
          </div>
        </div>
        <!-- end summary -->

        <!-- panels -->
        <div class="pb-5" style="flex:1; min-width: 180px;">
          <!-- alert SII-->
          <v-alert text outlined class="inf-alert-blue" rounded dense>
            <div class="d-flex pa-0 align-center">
              <v-icon color="blue-500">mdi-alert</v-icon>
              <div class="ml-6">
                <span class="body-1 font-weight-bold blue-500--text">Documento de compras en SII</span>
                <span class="d-block body-2 pb-1 blue-500--text">
                  Este documento fue obtenido del registro de compras del SII.
                  <span class="d-block">Por favor solicita el documento XML al proveedor.</span>
                </span>
              </div>
              <v-spacer />
              <div>
                <v-btn @click="modalEmail = true" outlined> Solicitar al proveedor</v-btn>
              </div>
            </div>
          </v-alert>
          <!-- end alert -->
          <!-- alert -->
          <v-col cols="12" class="pa-0 mb-5" v-if="instance.status === 'PEN' && daysLeftAlert > 0">
            <v-alert text outlined :class="daysLeftAlert >= 5 ? 'inf-alert-blue' : 'inf-alert-yellow'" rounded dense>
              <div class="d-flex pa-0 align-center alert-conten">
                <v-icon :color="daysLeftAlert >= 5 ? 'blue-500' : 'yellow-500'">mdi-calendar-alert</v-icon>
                <div :class="`ml-3 ${daysLeftAlert >= 5 ? 'blue-500' : 'yellow-500'}--text`">
                  <span class="body-1 font-weight-bold ">Días restantes</span>
                  <span class="d-block body-2 pb-1">
                    Te queda{{daysLeftAlert > 1 ? 'n' : ''}} <span class="font-weight-bold">{{daysLeftAlert }} {{ daysLeftAlert > 1 ? 'días' : 'día'}}</span> para la respuesta comercial del documento
                    <div class="d-block" v-if="btnDisable >= 7">
                      Si deseas aceptar o reclamar este documento, deberá hacerlo directamente en el SII.
                    </div>
                  </span>
                </div>
              </div>
            </v-alert>
          </v-col>
          <!-- end alert -->
          <!-- compliance -->
          <v-card flat class="mb-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-bold">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-n1 subtitle-2 grey-500--text font-weight-semibold d-flex align-center"><v-icon
                        class="mr-2" size="14" color="grey-500">mdi-shield-check</v-icon>Respuesta comercial
                    </span>
                  </v-col>
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="px-5 py-4">
              <PermissionDenied class="mt-n8" v-if="!$helpers.hasPermission(['add_swapresponse'])" :isDetail="true" />
              <v-list class="v-list-form-2 transparent pa-0" width="100%" v-else>
                <v-skeleton-loader v-if="loadingSwap" class="rounded-md" type="list-item-three-line" />
                <template v-else>
                <v-list-item>
                  <v-list-item-title class="grey-700--text subtitle-2">Estado</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip v-if="['PEN', null].includes(instance?.status)" color="yellow-100"
                      class="font-weight-semibold" small label>Sin respuesta<v-icon right>mdi-clock</v-icon></v-chip>
                    <v-chip v-else-if="['ERM', 'PAG', 'ACD'].includes(instance?.status)" class="font-weight-semibold"
                      color="blue-100" small label>Aceptado<v-icon
                        right>mdi-check</v-icon></v-chip>
                    <v-chip v-else-if="['RFT', 'RFP', 'RCD'].includes(instance?.status)" class="font-weight-semibold"
                      color="red-100" small label>Reclamado<v-icon right>mdi-close</v-icon></v-chip>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <div>
                  <v-list-item v-if="!['PEN', null].includes(swap?.status) && swap?.reply_to">
                    <v-list-item-title class="grey-700--text subtitle-2" v-if="swap?.status">Respondido
                      a</v-list-item-title>
                    <v-list-item-title class="grey-700--text subtitle-2" v-else>Responder a</v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="body-2 text--disabled font-italic" v-if="!swap?.reply_to">Sin especificar</span>
                      {{ swap?.reply_to }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                      <v-list-item-title class="grey-700--text subtitle-2">Detalle del estado</v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="instance.status === 'ERM'">Otorga recibo de mercaderías o servicios</span>
                        <span v-else-if="instance.status === 'ACD'">Acepta contenido del documento</span>
                        <span v-else-if="instance.status === 'PAG'">ADTE pagado al contado</span>
                        <span v-else-if="instance.status === 'RCD'">Reclamo al contenido del documento</span>
                        <span v-else-if="instance.status === 'RFP'">Reclamo por falta parcial de mercaderías</span>
                        <span v-else-if="instance.status === 'RFT'">Reclamo por falta total de mercaderías</span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </v-list-item-subtitle>
                    </v-list-item>
                  <v-list-item v-if="instance?.reply_by && instance?.reply_date">
                    <v-list-item-title class="grey-700--text subtitle-2">Respondido por</v-list-item-title>
                    <v-list-item-subtitle>{{ instance?.reply_by }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item v-if="instance?.date_reply && instance?.reply_by">
                    <v-list-item-title class="grey-700--text subtitle-2">Procesado el</v-list-item-title>
                    <v-list-item-subtitle>{{ instance?.date_reply | dateTime }}</v-list-item-subtitle>
                  </v-list-item>
                </div>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
          <!-- end compliance -->

          <!-- pdf -->
          <v-expansion-panels class="mt-5" v-model="panelPDF">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-4">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 grey-500--text font-weight-semibold"><v-icon class="mr-2" small
                        color="grey-500">mdi-text-box</v-icon>Visualizar PDF</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0" style="min-height: 300px;">
                <template v-if="!renderUrl">
                  <v-divider />
                  <v-col cols="6" class="mx-auto mt-5 pb-0 px-5">
                    <v-empty-state type="pending" id="pending" :isFree="true"
                      title="No existe la previsualización del PDF porque no ha llegado a nuestra casilla de intercambio. Contacta al proveedor."
                      customClass="mt-8" :height="130"></v-empty-state>
                  </v-col>
                </template>
                <div v-show="renderUrl">
                  <render-pdf :documentType="instance.document_type | nameDocumentsType" :number="instance.number"
                    :loaderRender="loaderRender" :renderUrl="renderUrl" :showToolbar="false" :flat="true"
                    :formatPDF.sync="formatPDF" :showFormat="true" />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end pdf -->

          <!-- events -->
          <v-card flat class="mt-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold"><v-icon size="18" left color="grey-500">mdi-timeline-clock</v-icon>Eventos</v-toolbar-title>
            </v-toolbar>
            <v-skeleton-loader v-if="eventLoading" class="mb-2 px-1 rounded-md" type="article" />
            <template v-else-if="eventsList.length">
              <v-divider />
              <v-card-text class="pa-0">
                <v-list class="transparent px-0 py-0">
                  <div v-for="(d, i) in events" :key="i">
                    <v-list-item :class="['px-5 py-0', {'mb-n4' : i !== events.length - 1}]" :key="i">
                      <v-list-item-icon>
                        <v-avatar color="blue-500" :size="28" v-if="d.name === 'sent_email'">
                          <v-icon color="white" :size="12">mdi-email-arrow-right</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500" :size="28" v-else-if="d.name === 'sent_email'">
                          <v-icon color="white" :size="12">mdi-bank</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500" :size="28" v-else-if="d.name === 'rcv_th_pos'">
                          <v-icon color="white" :size="12">mdi-printer-pos</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500" :size="28" v-else-if="['sent_agent', 'sent_final'].includes(d.name)">
                          <v-icon color="white" :size="12">mdi-source-repository</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500"  :size="28" v-else-if="['rcv_th_js', 'rcv_th_ws'].includes(d.name)">
                          <v-icon color="white" :size="12">mdi-cloud-braces</v-icon>
                        </v-avatar>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-n5">
                        <span class="d-block grey-700--text body-1">
                          <span class="font-weight-bold d-flex" v-if="d.name === 'sent_email'">Documento
                            <span class="text-lowercase blue-200--text ml-1">solicitado</span>
                            <v-tooltip v-if="d.name === 'sent_email'" :open-on-hover="emailEventsList(d.detail).length > 60" color="white-0" bottom :nudge-right="30" transition="scale-transition" max-width="300">
                            <template v-slot:activator="{on}">
                                <div v-on="on" class="d-inline-block ml-1 text-truncate" style="max-width: 90%;">
                                  a {{ emailEventsList(d.detail)?.replace(/,/g, ', ') }}
                                </div>
                            </template>
                            <span v-if="emailEventsList(d.detail).length > 60" class="d-block px-3 py-2">{{ emailEventsList(d.detail)?.replace(/,/g, ', ') }}</span>
                          </v-tooltip>
                          </span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_email'">Documento <span class="blue-200--text">solicitado a {{$t('generals.SII')}}</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_pos'">Recibido en <span class="blue-200--text">POS</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_agent'">Enviado al <span class="blue-200--text">agente</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_final'">Enviado al <span class="blue-200--text">agente con estado final</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_js'">Recibido por <span class="blue-200--text">API-REST</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_ws'">Recibido por <span class="blue-200--text">SOAP</span></span>
                        </span>
                        <span class="d-block body-2 grey-500--text">
                          <span>por <span class="font-weight-semibold">Axteroid</span> a las </span>
                          {{d.created | dateTime}}
                        </span>
                       </v-list-item-title>
                    </v-list-item>
                  </div>
                  <template v-if="eventsList.length > 3">
                    <v-divider class="mt-4" />
                    <v-btn class="mt-1 mb-n1 mx-5" color="blue-500" @click="showMoreEvents=!showMoreEvents" text>
                      {{ !showMoreEvents ? 'Ver todos los eventos' : 'Ver menos eventos' }} </v-btn>
                  </template>
                </v-list>
              </v-card-text>
            </template>
          </v-card>
          <!-- end events -->
        </div>
        <!-- end panels -->
      </div>
    </v-col>

    <!-- dialog swap update -->
    <v-dialog v-model="dialogSwap" width="410" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">{{ dialogSwapType !==
              'reclaim' ? 'Aceptar' : 'Reclamar' }} documento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogSwap = false" icon small retain-focus-on-click><v-icon color="grey-300--text"
                    size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-row class="pt-8" align="center" justify="center">
            <img :src="require(`@/assets/backgrounds/modal-aceptar-rechazar.svg`)" :width="370" />
          </v-row>
          <v-col class="pt-8 px-0 pb-0">
            <v-list class="pa-0 transparent" three-line>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title :class="{ 'font-weight-semibold': dialogSwapType !== 'reclaim' }">{{ dialogSwapType
                    ===
                    'reclaim' ? 'Selecciona la respuesta al documentos.' : 'Acuse de recibo del documento'
                    }}</v-list-item-title>
                  <v-list-item-subtitle class="pt-2" v-if="dialogSwapType === 'reclaim'">
                    <v-autocomplete v-model="swapResponse.status" :items="swapStatuses" item-text="label"
                      item-value="value" single-line hide-details dense return-object outlined />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="dialogSwap = false" outlined>Cancelar</v-btn>
          <v-btn @click="updateSwap" :loading="$store.state.irs.createLoader" color="blue-500">{{ dialogSwapType !==
            'reclaim' ? 'Aceptar' : 'Reclamar' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog swap update -->

    <!-- dialog confirm swap update -->
    <v-dialog v-model="dialogConfirmSwap" width="838" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">{{ dialogSwapType !==
              'reclaim' ? 'Aceptar' : 'Reclamar' }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirmSwap = false" icon small retain-focus-on-click><v-icon color="grey-300--text"
                    size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="188" />
            </v-col>
            <v-col class="pr-4 body-1 pl-6">
              Se registrará el evento <span class="font-weight-bold text-lowercase">{{ swapResponse.status.label ||
                swapStatuses[0].label }}</span> en el registro de compras del {{ $t('generals.SII') }}, y se le enviará,
              una
              notificación al proveedor informando la respuesta del documento.

              <span class="d-block body-1 pt-3">Al confirmar esta acción, no habrá vuelta atrás.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para
                continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />

              <!-- alert -->
              <v-alert class="inf-alert-blue blue-500--text rounded-md mt-4" :height="50" dense text>
                <div class="d-flex pa-0 my-1 pt-1">
                  <v-icon color="blue-500" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4 text-description">
                    <span class="body-2" style="line-height: 14px;">Esta acción
                      <span class="font-weight-bold">podría tardar varios minutos</span> en completarse.
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="dialogConfirmSwap = false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="dialogSwap = false, updateSwap()" :loading="$store.state.irs.createLoader"
            :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm swap update -->

    <!-- email -->
    <v-dialog v-model="modalEmail" width="700" persistent :scrollable="true" no-click-animation overlay-color="grey-500">
      <v-card min-height="300px">
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold d-flex align-center"><v-icon
                color="grey-300--text" left>mdi-email</v-icon>Solicitar al proveedor</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="modalEmail = false" icon small retain-focus-on-click><v-icon color="grey-300--text"
                    size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-col class="pa-0">
            <v-card class="transparent rounded-0 pa-0" flat>
              <v-card-subtitle class="background pt-5 pb-3 px-5">
                <div class="d-flex align-center">
                  <span class="grey-700--text body-1" style="min-width: 60px;">Para</span>
                  <v-combobox class="a-combobox" v-model="$v.messages.recipient.$model"
                    :error="$v.messages.recipient.$error" hide-details :items="emails" outlined required hide-selected
                    :placeholder="!messages.recipient ? 'Escribe el email del destinatario' : ''" @change="setEmails"
                    multiple hide-no-data deletable-chips small-chips single-line dense autocomplete="off">
                    <template v-slot:selection="data">
                      <v-chip class="pr-2 ma-1" small v-bind="data.attrs" :input-value="data.selected" close
                        @click="data.select" @click:close="remove(data.item)">{{ data.item }}</v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center mt-3">
                  <span class="grey-700--text body-1" style="min-width: 60px;">Asunto</span>
                  <v-text-field v-model="$v.messages.subject.$model" :error="$v.messages.subject.$error" outlined
                    required single-line dense maxlength="60" hint="Escribe un asunto para el mensaje" persistent-hint
                    :hide-details="!$v.messages.subject.$error" />
                </div>
                <!-- <v-col class="pa-0 mt-2">
                  <span class="grey-700--text body-1 mr-5">Archivos adjuntos</span>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="pdf" color="blue-500" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>{{$store.state?.auth?.account?.country === 'PE' ? number.replace(/[ ]/g, '') : number}}.pdf
                  </v-chip>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="xml" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>{{$store.state?.auth?.account?.country === 'PE' ? number.replace(/[ ]/g, '') : number}}.xml
                  </v-chip>
                </v-col> -->
              </v-card-subtitle>
              <v-divider />
              <v-card-text class="px-0 mt-2 mx-auto bgPanel overflow-y-auto text-center" style="max-height: 450px;">
                <v-alert border="top" class="pa-0 mx-auto" color="var(--light-blue-500)" colored-border
                  elevation="0" :style="`width: ${$vuetify.breakpoint.width < 1270 ? 465 : 564}px;`" />
                <v-sheet class="mx-auto" :width="$vuetify.breakpoint.width < 1270 ? 470 : 570"
                  style="border-radius: 10px; margin-top: -17px;" outlined>
                  <reminder-documents-email :instance="instance" :activeDocument="activeDocument"
                    :color-background="account.color_background" :message.sync="messages.text" />
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn @click="modalEmail = false" outlined>Cancelar</v-btn>
          <v-btn @click="createMessages" :loading="$store.state.messages.createLoader" color="blue-500">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end email -->
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { minLength, maxLength, required } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import ChipStatus from '@/components/commons/ChipStatus'
import VModal from '@/components/commons/VPanel'
import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
import RenderPdf from '@/components/files/RenderPdf'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import ReminderDocumentsEmail from '@/components/reminders/documents/Email'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
import DocumentTypes from '@/collections/documentTypes'
import numeral from 'numeral'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  mixins: [
    GenericViewMixin,
    RenderViewMixin,
    TaxAgencyViewMixin
  ],
  components: {
    ReminderDocumentsEmail,
    ChipStatus,
    MiniHeader,
    VModal,
    SkeletonDetail,
    RenderPdf,
    DocumentsList,
    PermissionDenied,
    VEmptyState
  },
  data: () => ({
    eventLoading: false,
    eventsList: [],
    modalEmail: false,
    formatPDF: 0,
    scroll: 0,
    emails: [],
    confirm: '',
    comments: '',
    dialogSwapType: '',
    dialogSwap: false,
    dialogConfirmSwap: false,
    loading: false,
    loadingSwap: false,
    panelIrsSii: [],
    panelComments: false,
    panelEmail: false,
    panelPDF: 0,
    panelRelatedDocuments: false,
    panelTotals: false,
    swap: {},
    swapResponse: {
      status: 'ERM'
    },
    swapStatuses: [
      // { value: 'ERM', label: 'Otorga recibo de mercaderías o servicios' },
      // { value: 'ACD', label: 'Acepta contenido del documento' },
      { value: 'RCD', label: 'Reclamo al contenido del documento' },
      { value: 'RFP', label: 'Reclamo por falta parcial de mercaderías' },
      { value: 'RFT', label: 'Reclamo por falta total de mercaderías' }
    ],
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Pendientes por recibir',
          route: 'PendingReceiveList'
        }
      ]
    },
    messages: {
      text: '',
      recipient: '',
      subject: '',
      type: ['pdf', 'xml']
    }
  }),
  computed: {
    ...mapState({
      instance: state => state.purchases.purchases_from_siiDetail
    }),
    activeDocument () {
      return DocumentTypes.find(d => d.id === this.instance.document_type)
    },
    daysLeftAlert () {
      const date = moment(this.instance.date_recep_sii).add(8, 'days')
      const duration = moment.duration(moment().diff(date))
      const days = Math.floor(duration.asDays())
      return days * (-1) > 0 && days * (-1) < 9 ? days * (-1) : null
    },
    btnDisable () {
      const duration = moment.duration(moment().diff(this.instance.date_recep_sii))
      const days = Math.floor(duration.asDays())

      return days * (-1) > 0 ? days * (-1) : days
    },
    number () {
      return this.$store.state?.auth?.account?.country === 'PE' ? `${this.instance.prefix} - ${this.instance.number}` : this.instance.number
    },
    events () {
      return !this.showMoreEvents ? this.eventsList.slice(0, 3) : this.eventsList
    },
    emailEventsList () {
      return (emails = null) => {
        const list = emails.match(/\[(.*?)\]/g).map(x => x.slice(1, -1))
        return list.toString().split(", ").map(email => email.replace(/'/g, "")).toString() // emails.match(/\[(.*?)\]/g).map(x => x.slice(1, -1)) // .toString().replace(/\'/g, '')
      }
    }
  },
  watch: {
    panelPDF (val) {
      this.modalRender = val
      if (val === 0 && this.instance.status !== 'PEN') {
        this.$vuetify.goTo(0, { duration: '0', easing: 'linear' })
        if (!this.renderUrl) this.renderDocument('purchases')
      }
      this.formatPDF = 1
    },
    formatPDF: {
      handler (val) {
        if (val === 1) this.renderDocument('documents')
        else {
          this.renderDocument('purchases', 'purchases', 'pdf', this.instance.id, { thermal_format: val })
        }
      },
      inmediate: true,
      deep: true
    },
    modalEmail (val) {
      if (!val) {
        this.$v.messages.$reset()
        this.messages = {
          recipient: ''
        }
        this.emails = []
        this.setMessages()
      } else {
        this.$store.dispatch('suppliers/LIST', {
          resource: 'suppliers/contacts',
          query: {
            supplier: this.instance.supplier.id
          }
        })
          .then((response) => {
            if (response.data.results.length) {
              this.emails = response?.data?.results?.map(({ email }) => email) ?? []
            }
          })
      }
    }
  },
  created () {
    this.eventLoading = true
    this.$store.dispatch('purchases/RETRIEVE', {
      resource: 'purchases_from_sii',
      id: this.$route.params.id,
      query: {
        expand: 'supplier'
      }
    })
    .then(() => {
      this.loadingSwap = true
      this.eventsList = []
      if (this.$helpers.hasPermission(['add_swapresponse'])) {
        this.$store.dispatch('irs/LIST', {
          resource: 'irs/sii/swaps',
          query: {
            purchases__in: this.$route.params.id
          }
        })
          .then((response) => {
            if (response.data.results) {
              this.swap = response.data.results[0]
            }
          })
          .finally(() => {
            this.loadingSwap = false
          })
      }
      this.setMessages()
      this.$store.dispatch('purchases/LIST', {
        resource: 'purchase_events',
        query: {
          purchase_from_sii: this.instance.id
        }
      })
      .then((response) => {
        this.eventsList = response.data.results
      })
      .finally(() => {
        this.eventLoading = false
      })
    })
  },
  methods: {
    setMessages () {
      this.messages.subject = `Envía ${this.activeDocument?.name ?? ''} N° ${this.number}`
      this.messages.text = `Estimado cliente, ${this.instance?.supplier?.name ?? ''},\n\n`
      this.messages.text += `Este documento ha sido extraído del registro de compras del SII. Para ${this.activeDocument?.name ?? ''} N° ${this.number}, `
      this.messages.text += `solicitamos que envíe el XML a la casilla de intercambio dte@facturachile.cl`
    },
    setEmails (e) {
      let array = []
      e.forEach(item => {
        let elm = item.replace(/ /g, ',')
        array = [...array, ...elm.split(',')]
      })
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      this.messages.recipient = array.filter(item => regex.test(item))
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    async handlePanel () {
      await this.$root.$emit('isClose', true)
      this.$router.replace({ name: 'DocumentsList' })
    },
    createMessages () {
      // this.messages.document = this.instance.id
      // this.messages.type = this.messages.type.length === 2 ? 'all' : this.messages.type.toString()

      this.$v.messages.$touch()
      if (this.$v.messages.$invalid) {
        return false
      }
      this.$store.dispatch('messages/CREATE', {
        resource: `purchases_from_sii/${this.$route.params.id}/request_purchase`,
        payload: { subject: this.messages.subject, emails: this.messages.recipient }
      })
        .then(() => {
          this.dialogMessages = false
          this.$dialog.message.info('Email enviado con éxito')
          this.messages.recipient = ''
          this.messages.type = []
          this.messages.text = `Estimado cliente, ${this.instance?.customer?.name ?? ''},\n\n`
          this.messages.text += `Adjunto ${this.activeDocument?.name ?? ''} N° ${this.number}, `
          this.messages.text += `correspondiente al ${moment(this.instance?.date).format('DD MMM YYYY')} `
          this.messages.text += `por un monto de ${numeral(this.instance?.total).format('0,0')} ${this.instance?.exchange?.currency_from ?? this.instance?.exchange?.currency_to ?? this.activeDocument?.currencies[0] ?? ''} \n\nAtentamente ${this.account?.name}`
          this.$v.messages.$reset()
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.modalEmail = false
        })
    },
    updateSwap () {
      this.$store.dispatch('irs/UPDATE', {
        resource: 'purchases_from_sii/',
        id: this.instance.id,
        payload: {
          status: this.dialogSwapType !== 'reclaim' ? 'ERM' : this.swapResponse.status.value
        }
      })
      .then((response) => {
        this.swap = response.data
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogSwap = false
        this.confirm = ''
        this.dialogSwapType = ''
      })
    }
  },
  validations: {
    messages: {
      recipient: {
        required
      },
      subject: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(60)
      },
      text: {
        required
      }
    }
  }
}
</script>

<style scoped>
.v-list--three-line .v-list-item .v-list-item__subtitle,
.v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 2 !important;
}

.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: auto;
}

.inf-alert-blue.v-alert--text.v-alert--outlined:not(.inf-alert) {
  border-radius: 10px !important;
  border: 1px solid var(--light-blue-500) !important;
}

.inf-alert-blue.v-alert--text:not(.inf-alert):before {
  background-color: var(--light-blue-500) !important;
  opacity: .3 !important;
}

.inf-alert-yellow.v-alert--outlined:not(.inf-alert-blue) {
  border-radius: 10px !important;
  border-color: var(--light-yellow-500) !important;
}
.alert-conten {
  position: relative;
}
.inf-alert-yellow:not(.inf-alert-blue).v-alert--text:before {
  background-color: #F8E4B1 !important;
  opacity: .8
}
</style>